import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EMAIL_VALIDATOR_PATTERN} from '../../../../shared/constants/constants';
import {UserActiveLog, Valet} from '../../../../admin/modules/admin-valet/models/valet';
import {USER_ROLE} from '../../../../shared/models/user';
import {GlobalDataService} from '../../../../shared/services/global-data.service';
import * as moment from 'moment';
import {Metropolis} from '../../../../admin/modules/admin-global/models/metropolis';
import {AdminGlobalMetropolisService} from '../../../../admin/modules/admin-global/services/admin-global-metropolis.service';
import {Subject, Subscription} from 'rxjs';
import {AuthService} from '../../../../shared/services/auth.service';
import {pairwise} from 'rxjs/internal/operators/pairwise';
import {map, takeUntil} from 'rxjs/operators';
import {CommonValetDisabledDialogComponent} from './common-valet-disabled-dialog/common-valet-disabled-dialog.component';
import {NbDialogService} from '@nebular/theme';
import {AdminValetService} from '../../../../admin/modules/admin-valet/services/admin-valet.service';
import {
  CommonValetInvoicingBlockDialogComponent
} from './common-valet-invoicing-block-dialog/common-valet-invoicing-block-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'pv-common-valet-profile-form',
  templateUrl: './common-valet-profile-form.component.html',
  styleUrls: ['./common-valet-profile-form.component.scss']
})
export class CommonValetProfileFormComponent implements OnInit, OnDestroy {
  public valet: Valet;
  public availableTitles: Promise<any>;
  public valetForm: FormGroup;
  public ROLE = USER_ROLE;
  public showDropdown = false;
  public valetStatus = [{
    value: 'external-worker',
    name: 'Prestataire'
  }, {
    value: 'internal-worker',
    name: 'Salarié'
  }];
  public valetPreferences = [{
    value: 'all',
    name: 'Toutes les distances'
  }, {
    value: 'short-distance',
    name: 'Courte distance'
  }, {
    value: 'long-distance',
    name: 'Longue distance'
  }];

  public disableReason: string;
  public disableComment: string;
  public invoicingBlockReason: string;
  public invoicingBlockComment: string;
  public historyLogs = [];

  @Input('valet')
  set setValetData(valet: Valet) {
    if (valet) {
      this.setFormData(valet);
    }
  }

  private subs: Subscription[] = [];
  private disableReasonSet = false;
  private invoicingBlockReasonSet = false;
  private destroy: Subject<void> = new Subject();

  @Input() availableMetropolis: Metropolis[] = [];
  @Input() isCreatingNew: boolean;
  @Input() viewAs: USER_ROLE;
  @Input() isLoading: boolean;
  @Input() hideSubmitButton: boolean;
  @Input() isCandidate: boolean;
  @Output() submitValetForm = new EventEmitter<Valet>();
  @Output() submitProfilePictureForm = new EventEmitter();

  constructor(private fb: FormBuilder,
              private globalMetropolisService: AdminGlobalMetropolisService,
              public globalDataService: GlobalDataService,
              private authService: AuthService,
              private dialogService: NbDialogService,
              private adminValetService: AdminValetService,
              private translateService: TranslateService) {
    this.initForm();
  }

  ngOnInit() {
    this.globalMetropolisService.getAllMetropolis().then(res => {
      this.availableMetropolis = res;
    });
    this.availableTitles = this.globalDataService.getPersonTitles();
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  initForm() {
    this.valetForm = this.fb.group({
      title: ['', Validators.required],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      email: ['', [Validators.required, EMAIL_VALIDATOR_PATTERN]],
      phone: ['', [Validators.required]],
      creationDate: [{value: '', disabled: true}],
      // ADDRESS
      metropolis: ['', [Validators.required]],
      addressLabel: [''],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      country: ['', Validators.required],
// BILLING
      siret: ['', Validators.required],
      billingAddress: ['', Validators.required],
      billingCity: ['', Validators.required],
      billingPostalCode: ['', Validators.required],
      billingCountry: ['', Validators.required],
      vatRegistered: [false],
      // REMUNERATION
      remunerationIBAN: ['', Validators.required],
      feesIBAN: ['', Validators.required],
      // Additional Information
      ready: [false, Validators.required],
      disableReason: [null],
      disableComment: [null],
      active: [true, Validators.required],
      sncfStatus: [false],
      warning: [false],
      blockRemuneration: [false],
      blockExpenses: [false],
      invoicingBlockReason: [null],
      invoicingBlockComment: [null],
      status: ['', Validators.required],
      preferredDistance: ['', Validators.required],
      wGarage: [''],
      hasWGarage: [false],
      enableLastbill: [false],
      copyAddresses: [true],
      drivingLicenseNumber: [null]
    });

    this.valetForm.get('status').valueChanges.pipe(takeUntil(this.destroy)).subscribe(status => {
      if (status === 'external-worker') {
        this.valetForm.get('siret').setValidators([Validators.required]);
      } else {
        this.valetForm.get('siret').setValidators([]);
      }
    });

    this.valetForm.get('ready').valueChanges.pipe(pairwise(), takeUntil(this.destroy)).subscribe(([readyBefore, readyAfter]) => {
      if (!this.disableReasonSet && readyBefore && !readyAfter) {
        this.askDisableReason('ready');
      }
    });

    this.valetForm.get('active').valueChanges.pipe(pairwise(), takeUntil(this.destroy)).subscribe(([activeBefore, activeAfter]) => {
      if (!this.disableReasonSet && activeBefore && !activeAfter) {
        this.askDisableReason('active');
      }
    });

    this.valetForm.get('blockRemuneration').valueChanges.pipe(pairwise(), takeUntil(this.destroy)).subscribe(([activeBefore, activeAfter]) => {
      if (!this.invoicingBlockReasonSet && !activeBefore && activeAfter) {
        this.askInvoicingBlockReason('blockRemuneration');
      }
    });

    this.valetForm.get('blockExpenses').valueChanges.pipe(pairwise(), takeUntil(this.destroy)).subscribe(([activeBefore, activeAfter]) => {
      if (!this.invoicingBlockReasonSet && !activeBefore && activeAfter) {
        this.askInvoicingBlockReason('blockExpenses');
      }
    });

    if (this.viewAs === USER_ROLE.VALET) {
      this.valetForm.removeControl('ready');
      this.valetForm.removeControl('active');
      this.valetForm.removeControl('sncfStatus');
      this.valetForm.removeControl('warning');
      this.valetForm.removeControl('status');
      this.valetForm.removeControl('enableLastbill');
      this.valetForm.removeControl('vatRegistered');
    }
  }

  private setFormData(valet: Valet) {
    this.initForm();
    this.valet = valet;
    if (this.valet.activeLogs) {
      this.valet.activeLogs = this.valet.activeLogs.sort((a, b) => {
        return Date.parse(b.date) - Date.parse(a.date); // Sort logs by date DESC
      });
    }
    if (this.valet.valetInvoicingBlockLogs) {
      this.valet.valetInvoicingBlockLogs = this.valet.valetInvoicingBlockLogs.sort((a, b) => {
        return Date.parse(b.date) - Date.parse(a.date);
      });
    }
    if (this.valet.activeLogs && this.valet.valetInvoicingBlockLogs) {
      this.historyLogs = [...this.valet.activeLogs, ...this.valet.valetInvoicingBlockLogs];
      this.historyLogs = this.historyLogs.sort((a, b) => {
        return Date.parse(b.date) - Date.parse(a.date);
      });
    } else if (this.valet.activeLogs) {
      this.historyLogs = [...this.valet.activeLogs];
    } else if (this.valet.valetInvoicingBlockLogs) {
      this.historyLogs = [...this.valet.valetInvoicingBlockLogs];
    }
    if (this.historyLogs.length > 0) {
      if (this.historyLogs[0].disableReason !== '' || this.historyLogs[0].disableReason !== null) {
        this.setDisableReason(this.historyLogs[0].disableReason);
      }
      if (this.historyLogs[0].disableComment !== '' || this.historyLogs[0].disableComment !== null) {
        this.disableComment = this.historyLogs[0].disableComment;
      }
    }

    if (valet.invoicingBlockReason !== '' || valet.invoicingBlockReason !== null) {
      this.setInvoicingBlockReason(valet.invoicingBlockReason);
    }
    if (valet.invoicingBlockComment !== '' || valet.invoicingBlockComment !== null) {
      this.invoicingBlockComment = valet.invoicingBlockComment;
    }
    this.valetForm.patchValue(
      Object.assign({}, valet, {
        birthDate: valet.birthDate ? moment(valet.birthDate) : '',
        metropolis: valet.metropolis?.id ? valet.metropolis.id : '',
        creationDate: valet.creationDate ? moment(valet.creationDate).format('DD-MM-YYYY') : ''
      })
    );

    if (!valet.addressLabel) {
      const addressComponents = [valet.address, valet.city, valet.postalCode, valet.country].filter(Boolean);
      const addressLabel = addressComponents.join(', ');
      this.valetForm.get('addressLabel').setValue(addressLabel);
    }
  }

  private askDisableReason(controlName: string) {
    this.openValetDisabledDialog().pipe(takeUntil(this.destroy)).subscribe(res => {
      res.dialog.close();
      if (res.data) {
        this.valetForm.get('disableReason').setValue(res.data.disableReason);
        this.valetForm.get('disableComment').setValue(res.data.disableComment);
        this.disableReasonSet = true;
      } else {
        this.valetForm.get(controlName).setValue(true);
      }
    });
  }

  private askInvoicingBlockReason(controlName: string) {
    this.openValetInvoicingBlockDialog().pipe(takeUntil(this.destroy)).subscribe(res => {
      res.dialog.close();
      if (res.data) {
        this.valetForm.get('invoicingBlockReason').setValue(res.data.invoicingBlockReason);
        this.valetForm.get('invoicingBlockComment').setValue(res.data.invoicingBlockComment);
        this.invoicingBlockReasonSet = true;
      } else {
        this.valetForm.get(controlName).setValue(false);
      }
    });
  }

  private openValetDisabledDialog() {
    const dialog = this.dialogService.open(CommonValetDisabledDialogComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false
    });
    return dialog.componentRef.instance.submitEvent.pipe(
      map(res => {
        return {
          dialog,
          data: res
        };
      })
    );
  }

  private openValetInvoicingBlockDialog() {
    const dialog = this.dialogService.open(CommonValetInvoicingBlockDialogComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false
    });
    return dialog.componentRef.instance.submitEvent.pipe(
      map(res => {
        return {
          dialog,
          data: res
        };
      })
    );
  }

  public toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  public submit() {
    const formData = this.valetForm.getRawValue();
    if (formData.birthDate) {
      formData.birthDate = moment(formData.birthDate).set({hour: 0, minute: 0, second: 0});
    }
    this.submitValetForm.emit(formData);
  }

  public loginAs() {
    this.authService.loginAs(this.valet.id).then(res => {
      localStorage.setItem('popvalet-access-token', res.token);
      localStorage.setItem('popvalet-user', JSON.stringify(res.user));
      window.location.href = window.location.origin;
    });
  }

  public setDisableReason(disableReason: string) {
    switch (disableReason) {
      case 'process':
        this.disableReason = 'Manquements de process répétitifs';
        break;
      case 'speeding':
        this.disableReason = 'Grand excès de vitesse';
        break;
      case 'delay':
        this.disableReason = 'Retards répétitifs';
        break;
      case 'behavior':
        this.disableReason = 'Comportement inapproprié';
        break;
      case 'withdrawal':
        this.disableReason = 'Absences / Plantages répétitifs';
        break;
      case 'asked':
        this.disableReason = 'Sur demande du Valet';
        break;
      case 'other':
        this.disableReason = 'Autre';
        break;
      default:
        this.disableReason = disableReason;
        break;
    }
  }

  public setInvoicingBlockReason(invoicingBlockReason: string) {
    switch (invoicingBlockReason) {
      case 'process':
        this.invoicingBlockReason = 'Manquements de process répétitifs';
        break;
      case 'speeding':
        this.invoicingBlockReason = 'Grand excès de vitesse';
        break;
      case 'delay':
        this.invoicingBlockReason = 'Retards répétitifs';
        break;
      case 'behavior':
        this.invoicingBlockReason = 'Comportement inapproprié';
        break;
      case 'withdrawal':
        this.invoicingBlockReason = 'Absences / Plantages répétitifs';
        break;
      case 'asked':
        this.invoicingBlockReason = 'Sur demande du Valet';
        break;
      case 'other':
        this.invoicingBlockReason = 'Autre';
        break;
      case 'hijacking':
        this.invoicingBlockReason = 'Détournement';
        break;
      case 'accident':
        this.invoicingBlockReason = 'Sinistre';
        break;
      default:
        this.invoicingBlockReason = '';
        break;
    }
  }

  public showDisableDate() {
    const logs: UserActiveLog[] = [];

    const lastDisableLog = this.adminValetService.getLastOfFirstGroup(this.valet.activeLogs, 'active', false);
    if (lastDisableLog) {
      logs.push(lastDisableLog)
    }

    const lastNotReadyLog = this.adminValetService.getLastOfFirstGroup(this.valet.activeLogs, 'ready', false);
    if (lastNotReadyLog) {
      logs.push(lastNotReadyLog);
    }

    const lastLog = logs.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))[0];

    return lastLog ? moment(lastLog.date).format('DD/MM/YYYY') : '';
  }

  public submitProfilePicture(formData) {
    this.submitProfilePictureForm.emit(formData);
  }
}
