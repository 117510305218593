<ng-container #templateContainer>
  <nb-card [class]="cardClass">
    <nb-card-header><h6>{{ title }}</h6></nb-card-header>
    <nb-card-body [innerHTML]="message"></nb-card-body>
    <nb-card-footer class="d-flex justify-content-end">
      <button *ngIf="showCancel" nbButton status="basic" (click)="cancelAction()">{{ cancel }}</button>
      <button nbButton class="ml-4" status="warning" (click)="confirmAction()">{{ confirm }}</button>
    </nb-card-footer>
  </nb-card>
</ng-container>
